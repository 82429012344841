import { useContext, useEffect } from 'react';
import { ModalContext } from '@layerise/design-core';
import { useLazyQuery } from '@apollo/client';
import { MemberBackupCodesDocument } from 'types/typed-document-nodes';
import { OrganisationContext } from 'contexts/OrganisationContext';
import { BackupCodesModal } from './BackupCodesModal';

export function BackupCodesAwareness() {
  const { me } = useContext(OrganisationContext);
  const { showModal } = useContext(ModalContext);
  const [getRecoveryCodes] = useLazyQuery(MemberBackupCodesDocument);

  useEffect(() => {
    if ((me?.required2FA || me?.twoFactorAuthEnabled) && me?.recoveryDataShown === false) {
      (async () => {
        const { data } = await getRecoveryCodes();
        if (!data?.recoveryData?.codes) return;
        showModal(BackupCodesModal, { recoveryData: data.recoveryData, actionRequired: true });
      })();
    }
  }, [me?.required2FA, me?.twoFactorAuthEnabled, me?.recoveryDataShown, showModal, getRecoveryCodes]);

  return null;
}
