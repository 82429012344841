import {
  AddonQuery,
  Role,
  EntityTransactionLog,
  FormStepTypeTemplate,
  SingleCustomerQuery,
} from 'types/typed-document-nodes';
import { formatDistanceShort } from 'lib/formatDistanceShort';
import { addWeeks, formatDistance, isBefore, startOfWeek } from 'date-fns';

export const combineMessages = (messages: (string | null | undefined)[]): string => {
  return messages
    .map(message => {
      const trimmedMessage = String(message).trim();
      if (trimmedMessage[trimmedMessage.length - 1] === '.') {
        return trimmedMessage.substring(0, trimmedMessage.length - 1);
      } else {
        return trimmedMessage;
      }
    })
    .join('. ');
};

export const isPowerUser = (role: Role | undefined) => {
  return role ? [Role.SuperAdmin, Role.OrganisationAdmin].includes(role) : false;
};

export const getFullName = (
  user: { firstName?: string | null; lastName?: string | null; email?: string | null } | null,
  options?: { short?: boolean }
) => {
  if (!user) return '';
  if (!user.firstName && !user.lastName && user.email) {
    return user.email;
  }
  if (options?.short) {
    return `${user.firstName || ''} ${user.lastName ? `${user.lastName[0]}.` : ''}`.trim();
  }
  return `${user.firstName || ''} ${user.lastName || ''}`.trim();
};

export const getShortenName = (user: { firstName?: string | null; lastName?: string | null }) => {
  if (!user.firstName) return '';

  return `${user.firstName} ${user.lastName ? `${user.lastName[0]}.` : ''}`.trim();
};

export const getAddonAmount = (addon?: AddonQuery['addon'], currency?: string): number | null => {
  if (!addon || !currency) {
    return null;
  }
  const amountKey = `amountIn${currency[0].toUpperCase()}${currency.substring(1)}` as keyof NonNullable<
    AddonQuery['addon']
  >;
  if (typeof addon[amountKey] === 'undefined') {
    console.error('Unable to determine amount for addon', { addon, currency });
  }
  return Number(addon[amountKey] || 0);
};

export const formatPlanValue = (
  value: number | null | undefined,
  prettify: boolean,
  hasUnlimited: boolean,
  postfix: string = ''
): string | number => {
  if (!value && hasUnlimited) {
    return 'Unlimited';
  }
  if (prettify) {
    return `${new Intl.NumberFormat('en-US').format(value || 0)}`;
  } else if (postfix) {
    return `${value || 0}${postfix}`;
  } else {
    // note: it is important to return a number in certain cases (e.g. for showing usage percent)
    return value || 0;
  }
};

export const isRtlLocale = (code: string) => ['fa', 'ar', 'he'].includes(code);

export const isBrowser = () => typeof window !== 'undefined' && typeof document !== 'undefined';

export const saveSvg = (linkLabel: string, svgEl?: SVGSVGElement) => {
  if (svgEl) {
    svgEl.setAttribute('version', '1.1');
    svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    const markup = svgEl.outerHTML;
    const b64 = btoa(markup);
    const aEl = document.createElement('a');
    aEl.setAttribute('download', linkLabel + '.svg');
    aEl.href = 'data:image/svg+xml;base64,\n' + b64;
    aEl.target = '_blank';
    document.body.appendChild(aEl);
    aEl.click();
  }
};

export const handleDownload = (fileUrl: string, fileName: string) => {
  const aEl = document.createElement('a');
  aEl.setAttribute('download', fileName);
  aEl.href = fileUrl;
  aEl.target = '_blank';
  document.body.appendChild(aEl);
  aEl.click();
  document.body.removeChild(aEl);
};

type Member = NonNullable<EntityTransactionLog['member']>;

export type TransactionLog = Pick<EntityTransactionLog, 'createdAt'> & {
  member:
    | (Pick<Member, 'firstName' | 'lastName' | 'email'> & {
        avatarImg: Pick<NonNullable<Member['avatarImg']>, 'dynamic'> | null;
      })
    | null;
};

export const formatUpdatedBy = (
  transactionLogs?: TransactionLog[],
  updatedAt?: string,
  longDistance?: boolean,
  shortLastname?: boolean
) => {
  const distance = !longDistance
    ? formatDistanceShort(new Date(transactionLogs?.[0]?.createdAt || updatedAt))
    : formatDistance(new Date(transactionLogs?.[0]?.createdAt || updatedAt), new Date());
  const member = transactionLogs?.[0]?.member;
  const updatedBy = member ? getFullName(member, { short: shortLastname }) : '';
  const updated = `${distance === 'now' ? distance : `${distance} ago`} ${updatedBy ? `by ${updatedBy}` : ''}`;
  return updated;
};

export const formatCustomerScore = (score: number): string => (!score ? '0' : score >= 10 ? '10.0' : score.toFixed(1));

export const getFirstParentMatch = (element: Element | null, selector: string): Element | null => {
  if (!element) {
    return element;
  }
  element.matches(selector) ||
    (element =
      element.nodeName.toLowerCase() === 'html' ? null : getFirstParentMatch(element.parentNode as Element, selector));
  return element;
};

export const addBackQueryString = (backRoute: string | undefined, urls: [string, string?]): [string, string?] => {
  if (!backRoute) return urls;
  return urls.map(u => `${u}?back=${encodeURIComponent(backRoute)}`) as [string, string?];
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const reorderResult = Array.from(list);
  const [removed] = reorderResult.splice(startIndex, 1);

  reorderResult.splice(endIndex, 0, removed);

  return reorderResult;
};

export const isLastWeek = (date: Date) => {
  const today = new Date();
  const lastWeekStart = startOfWeek(addWeeks(today, -1));
  const lastWeekEnd = addWeeks(lastWeekStart, 1);
  return !isBefore(date, lastWeekStart) && isBefore(date, lastWeekEnd);
};

export const getFullAddressFromFormValues = (
  formValues: NonNullable<SingleCustomerQuery['customer']>['formValues'] | undefined
) => {
  const containsAddrees = formValues?.find(i => i.stepType.template === FormStepTypeTemplate.Address);
  if (!formValues || !containsAddrees) {
    return null;
  }
  const country = formValues.find(i => i.control.key === 'country')?.value || '';
  const city = formValues.find(i => i.control.key === 'city')?.value || '';
  const postalCode = formValues.find(i => i.control.key === 'postalCode')?.value || '';
  const address1 = formValues.find(i => i.control.key === 'addressLine1')?.value || '';
  const address2 = formValues.find(i => i.control.key === 'addressLine2')?.value || '';

  return {
    country,
    city,
    address1,
    address2,
    postalCode,
  };
};

export function isNumeric(value: string) {
  return /^-?\d+$/.test(value);
}
