import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { NextPage } from 'next';
import { endpoint } from './endpoints';
import React, { useMemo } from 'react';
import { AppPropsWithLayout } from 'types/app';
import { NextRouter, useRouter } from 'next/router';
import { conversationsMergeFunction } from 'components/chat/lib';

let globalApolloClient: ApolloClient<NormalizedCacheObject> | null = null;
let prevWorkspaceSlug: string | null | undefined = null;

function initApolloClient(initialState?: NormalizedCacheObject, router?: NextRouter) {
  const workspaceSlug = router?.query.id;
  if (!globalApolloClient || workspaceSlug !== prevWorkspaceSlug) {
    const httpLink = new HttpLink({
      uri: endpoint.http,
      credentials: 'include',
      headers: {
        layeriseSecret: process.env.LAYERISE_SECRET || '',
        ...(workspaceSlug && {
          'x-requested-workspace-slug': workspaceSlug as string,
        }),
      },
      fetch,
    });

    globalApolloClient = new ApolloClient({
      ssrMode: true,
      connectToDevTools: process.env.NODE_ENV === 'development',
      link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            console.error({ graphQLErrors });
            graphQLErrors.forEach(({ extensions }) => {
              if (extensions && extensions.requireLogIn) {
                console.log('Member was logged out');
                router?.push('/auth');
              }
            });
          }
          if (networkError) {
            console.error({ networkError });
          }
        }),
        httpLink,
      ]),
      cache: new InMemoryCache({
        possibleTypes: {
          Media: ['Image', 'Video', 'Pdf'],
          LinkedComponent: [
            'FeaturedStory',
            'FeaturedArticle',
            'FeaturedVideo',
            'ProductCertification',
            'Shortcut',
            'Download',
            'SocialComponent',
            'ExternalLink',
            'ExternalReviewComponent',
            'Feedback',
            'WarrantyRegistration',
            'FeaturedReminder',
            'ProductRegistrationWidget',
            'LocationExternalLink',
            'LocationSocialComponent',
            'Install',
          ],
        },
        typePolicies: {
          Query: {
            fields: {
              getWorkspaceActiveConversations: {
                keyArgs: false,
                merge: conversationsMergeFunction,
              },
              getWorkspaceArchivedConversations: {
                keyArgs: false,
                merge: conversationsMergeFunction,
              },
            },
          },
        },
      }).restore(initialState || {}),
      ssrForceFetchDelay: 100,
      name: 'admin',
      version: '1.0',
    });

    prevWorkspaceSlug = workspaceSlug as string;
  }

  // client side page transition to an SSG page => update Apollo cache
  else if (globalApolloClient && initialState) {
    globalApolloClient.cache.restore({
      ...globalApolloClient.cache.extract(),
      ...initialState,
    });
  }
  return globalApolloClient;
}

export const useApollo = (
  pageProps: React.ComponentProps<NextPage<AppPropsWithLayout & { apolloStaticCache?: NormalizedCacheObject }>>
) => {
  const router = useRouter();
  return useMemo(() => initApolloClient(pageProps.apolloStaticCache, router), [pageProps.apolloStaticCache, router]);
};
